import React, { useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useGetTransactionsQuery } from 'state/api';
import Header from 'components/Header';
import DataGridCustomToolbar from 'components/DataGridCustomToolbar';

const Transactions = () => {
  const theme = useTheme();

  // values to be sent to the backend
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sort, setSort] = useState({});
  const [search, setSearch] = useState('');

  const [searchInput, setSearchInput] = useState('');
  const { data, isLoading } = useGetTransactionsQuery({
    page,
    pageSize,
    sort: JSON.stringify(sort),
    search,
  });

  const columns = [
    {
      field: '_id',
      headerName: 'ID',
      flex: 1,
    },
    {
      field: 'userId',
      headerName: 'Номер клиента',
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Создан',
      flex: 1,
    },
    {
      field: 'products',
      headerName: '# продуктов',
      flex: 0.5,
      sortable: false,
      renderCell: (params) => params.value.length,
    },
    {
      field: 'cost',
      headerName: 'Цена',
      flex: 1,
      renderCell: (params) => `$${Number(params.value).toFixed(2)}`,
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Транзакции \ Сделки" subtitle="Общий лист транзакций" />
      <Box
        height="80vh"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: theme.palette.primary.light,
          },
          '& .MuiDataGrid-footerContainer': {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: 'none',
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={(data && data.transactions) || []}
          columns={columns}
          rowCount={(data && data.total) || 0}
          rowsPerPageOptions={[20, 50, 100]}
          pagination
          page={page}
          pageSize={pageSize}
          paginationMode="server"
          sortingMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onSortModelChange={(newSortModel) => setSort(...newSortModel)}
          components={{ Toolbar: DataGridCustomToolbar }}
          componentsProps={{
            toolbar: { searchInput, setSearchInput, setSearch },
          }}
          localeText={{
            // https://mui.com/x/react-data-grid/localization/
            toolbarDensity: 'Размер',
            toolbarDensityCompact: 'Уменшить',
            toolbarDensityStandard: 'Средний',
            toolbarDensityComfortable: 'Большой',
            toolbarColumns: 'Колонки',
            toolbarColumnsLabel: 'Выделить колонки',
            // Columns panel text
            columnsPanelTextFieldLabel: 'Найти колонку',
            columnsPanelTextFieldPlaceholder: 'Заголовок колонки',
            columnsPanelDragIconLabel: 'Порядок колонок',
            columnsPanelShowAllButton: 'Показать все',
            columnsPanelHideAllButton: 'Скрыть все',

            // Export selector toolbar button text
            toolbarExport: 'Экспорт',
            toolbarExportLabel: 'Экспорт',
            toolbarExportCSV: 'Сохранить таблицу',
            toolbarExportPrint: 'Печать',
            toolbarExportExcel: 'Сохранить таблицу Excel',
          }}
        />
      </Box>
    </Box>
  );
};

export default Transactions;
